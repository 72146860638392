import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/LoginPage";
import { Provider } from "react-redux";
import store from "./store";
import SignUp from "./pages/SignUpPage";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PropertyManagement from "./pages/PropertyManagement";
import TenantPage from "./pages/TenantPage";
import LandlordPage from "./pages/LandlordPage";
import PrivateRoute from './hocs/PrivateRoute'

const App = () => (
  <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/policy" element={<PrivacyPolicy />} />
          <Route path="/propertyManager" element={
            <PrivateRoute>
            <PropertyManagement />
            </PrivateRoute>
            } />
          <Route path="/report" element={
            <PrivateRoute>
            <TenantPage />
            </PrivateRoute>} />
          <Route path="/landlord" element={
            <PrivateRoute>
            <LandlordPage />
            </PrivateRoute>
            } />
          <Route path="*" element={<Navigate to="/"/> } />
        </Routes>
      </BrowserRouter>
  </Provider>
);

export default App;
