import React from "react";

function Home() {
  return (
    <>
      <head>
        <script>
          {`window.va = window.va || function () { (window.vaq = window.vaq || []).push(arguments); };`}
        </script>
        <script defer src="/_vercel/insights/script.js"></script>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Coflow - Property management on autopilot</title>

        {/* Meta SEO */}
        <meta name="title" content="Coflow - AI Property Management" />
        <meta
          name="description"
          content="AI Property Management. Property management on autopilot."
        />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="Coflow" />

        {/* Social media share */}
        <meta property="og:title" content="Coflow" />
        <meta property="og:site_name" content="Coflow" />
        <meta property="og:url" content="https://www.joincoflow.com/" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />

        {/* Favicon */}
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <script async defer src="https://buttons.github.io/buttons.js"></script>
      </head>

      {/* Google tag (gtag.js) */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-7KE0TDG5SQ"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-7KE0TDG5SQ');
        `}
      </script>
      <body>
        <header className="fixed w-full">
          <nav className="bg-gray-50 border-gray-200 py-2.5">
            <div className="flex flex-wrap items-center justify-between max-w-screen-xl px-4 mx-auto">
              <a href="/" className="flex items-center">
                <img
                  src="/favicon.png"
                  className="h-6 mr-3 sm:h-9"
                  alt="Coflow Logo"
                />
                <span className="self-center text-xl font-semibold whitespace-nowrap pl-4">
                  Coflow
                </span>
              </a>
              <div className="hidden lg:flex items-center lg:order-2">
                <a
                  href="/login"
                  className="inline-flex items-center justify-center w-full px-5 py-3 text-sm font-medium text-center text-white border rounded-lg sm:w-auto bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 focus:outline-none"
                >
                  Automate your Property Management ✨
                </a>
                <a
                  href="https://cal.frontapp.com/shopscribec6bb/alexzhou/7b9a60a7"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-4 inline-flex items-center justify-center w-full px-5 py-3 text-sm font-medium text-center text-white border rounded-lg sm:w-auto bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 focus:outline-none"
                >
                  Chat with us
                </a>
              </div>
              <button
                data-collapse-toggle="mobile-menu-2"
                type="button"
                className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                aria-controls="mobile-menu-2"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <svg
                  className="hidden w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          </nav>
        </header>

        {/* Start block */}
        <section className="bg-gray-50 py-12">
          <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-24 lg:grid-cols-12 lg:pt-40">
            <div className="mr-auto place-self-center lg:col-span-7">
              <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl">
                Automate property repairs and maintenance
                {" "}
                <span className="underline">for free</span>
              </h1>
              <p className="max-w-2xl mb-6 font-light text-gray-600 lg:mb-8 md:text-lg lg:text-xl">
                Automate work orders by using AI to gather quotes and earliest availability from dozens of the best contractors when you need them
              </p>
              <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                <a
                  href="/login"
                  className="inline-flex items-center justify-center w-full px-5 py-3 text-sm font-medium text-center text-white border rounded-lg sm:w-auto bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 focus:outline-none"
                >
                  Automate your Property Management ✨
                </a>
                <a
                  href="https://cal.frontapp.com/shopscribec6bb/alexzhou/7b9a60a7"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center justify-center w-full sm:w-auto px-5 py-3 text-sm font-medium text-center text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:ml-4 sm:mr-0"
                >
                  Chat with us
                </a>
              </div>
            </div>
            <div className="lg:col-span-5">
              <video
                src="https://coflow.s3.eu-west-2.amazonaws.com/coflow.mov"
                autoPlay
                loop
                muted
                className="w-full h-auto"
              >
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </section>
        {/* End block */}

        {/* Start block */}
        <section className="py-8">
          <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-0 lg:px-6">
            <div className="max-w-screen-md mx-auto mb-0 text-center lg:mb-4">
              <h2 className="mb-0 pt-8 text-3xl font-semibold tracking-tight text-gray-900">
                No more manually dealing with maintenance request admin
              </h2>
              <br />
              <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl">
                Our AI instantly reaches out to dozens of contractors or handymen when you need them, streamlines landlord approval, and coordinates repairs with your tenants
              </p>
            </div>
            <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">

              {/* Pricing Card */}
              <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 border-gray-100 xl:p-8">
                <p className="font-medium text-gray-900 sm:text-lg">
                  Instant setup 🔌
                </p>
                <br />
                <p className="font-light text-gray-600 sm:text-lg">
                  Connect your CRM and tools to automate work orders, while staying personal where needed.
                </p>
              </div>

              {/* Pricing Card */}
              <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 border-gray-100 xl:p-8">
                <p className="font-medium text-gray-900 sm:text-lg">
                  You're in control 🫵
                </p>
                <br />
                <p className="font-light text-gray-600 sm:text-lg">
                  Bring your preferred contractors, or use our AI to auto-source earliest available tradespeople.
                </p>
              </div>

              {/* Pricing Card */}
              <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 border-gray-100 xl:p-8">
                <p className="font-medium text-gray-900 sm:text-lg">
                  Happy landlords and tenants 🤝
                </p>
                <br />
                <p className="font-light text-gray-600 sm:text-lg">
                  Landlords make decisions faster because they have numerous quotes. Tenants get repairs done ASAP.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* End block */}

        {/* Start block */}
        <section className="bg-gray-50 py-8">
          <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-0 lg:px-6">

            {/* How it works section */}
            <div className="max-w-screen-md mx-auto mb-0 text-center lg:mb-4">
              <h2 className="mb-4 text-3xl font-semibold tracking-tight text-gray-900">
                How it works
              </h2>
              <div className="flex flex-col items-center sm:flex-row sm:justify-center sm:space-x-2">
                <p className="font-light text-gray-500 md:text-lg lg:text-xl">
                  Tenants submit requests into your CRM
                </p>
                <p className="font-light text-gray-500 md:text-lg lg:text-xl">
                  👉
                </p>
                <p className="font-light text-gray-500 md:text-lg lg:text-xl">
                  We take over the rest for free!
                </p>
              </div>
            </div>

            {/* Image Section */}
            <div className="max-w-screen-md mx-auto mb-4 text-center">
              <img src="quotes.png" alt="Quotes process" className="mx-auto w-auto h-auto" />
            </div>

            <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
              {/* Column 1 */}
              <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 border-gray-100 xl:p-8">
                <h2 className="mb-4 font-medium text-gray-900 sm:text-lg">
                  1.  Auto-source Quotes
                </h2>
                <p className="font-light text-gray-600 sm:text-lg">
                  Add info about repair needed. We reach out to your preferred contractors, or auto-source earliest available tradespeople.
                </p>
              </div>

              {/* Column 2 */}
              <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 border-gray-100 xl:p-8">
                <h2 className="mb-4 font-medium text-gray-900 sm:text-lg">
                  2.  Approve Quote
                </h2>
                <p className="font-light text-gray-600 sm:text-lg">
                  Quotes and availability aggregated into one place. You select contractor, or one-click to send quotes to landlord for approval.
                </p>
              </div>

              {/* Column 3 */}
              <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 border-gray-100 xl:p-8">
                <h2 className="mb-4 font-medium text-gray-900 sm:text-lg">
                  3.  Sit Back and Relax
                </h2>
                <p className="font-light text-gray-600 sm:text-lg">
                  We coordinate between contractor and tenant to streamline the work order. Easy and seamless invoice payment after.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* End block */}

        {/* Company block */}
        <section className="py-8">
          <div className="max-w-screen-xl mx-auto text-center">
            <h2 className="mb-4 pt-8 text-3xl font-semibold tracking-tight text-gray-900">
              Our investors have also supported
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-8 justify-items-center">
              <div className="w-24">
                <img src="/airbnb.svg" alt="airbnb" className="object-contain grayscale" />
              </div>
              <div className="w-24">
                <img src="/kettel.png" alt="kettel" className="object-contain grayscale" />
              </div>
              <div className="w-24">
                <img src="/better.png" alt="better" className="object-contain grayscale" />
              </div>
              <div className="w-24">
                <img src="/tenant-turner.png" alt="tenant-turner" className="object-contain grayscale" />
              </div>
              <div className="w-24">
                <img src="/guesty.png" alt="guesty" className="object-contain grayscale" />
              </div>
            </div>
          </div>
        </section>


        <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />
        <div className="text-center">
          <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4 justify-center"> {/* Matching top buttons style */}
            <a
              href="/signup"
              className="inline-flex items-center justify-center w-full sm:w-auto px-5 py-3 text-sm font-medium text-center text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 rounded-lg"
            >
              Automate your Property Management ✨
            </a>
            <a
              href="https://cal.frontapp.com/shopscribec6bb/alexzhou/7b9a60a7"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center justify-center w-full sm:w-auto px-5 py-3 text-sm font-medium text-center text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 rounded-lg"
            >
              Chat with us
            </a>
          </div>
          <span className="block text-sm text-center text-gray-500 mb-4">
            alexander@joincoflow.com
          </span>
          <span className="block text-sm text-center text-gray-500 mb-4">
            <a href="/policy" className="hover:underline">
              Your data is secure with us. Check out our Privacy Policy 👮
            </a>
          </span>
          <ul className="flex justify-center mt-5 space-x-5">
            <br />
          </ul>
        </div>
      </body>
      <script src="https://unpkg.com/flowbite@1.4.1/dist/flowbite.js"></script>
    </>
  );
}

export default Home;
